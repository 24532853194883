.nav_bar {
  height: 70px;
  width: 100dvw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #000000d5;
  animation-name: bgcolorAnimation;
  animation-duration: 1ms; /* Firefox requires this to apply the animation */
  animation-direction: alternate;
  animation-timeline: scroll(block nearest);
}

@keyframes bgcolorAnimation {
  from {
    transform: background-color#000000d5;
  }
  to {
    transform: background-color#ede58ed4;
  }
}

.button1 {
  padding: 6px 10px 6px 10px;
  font-weight: 900;
  border-radius: 8px;
  color: #ffef08;
  background-color: rgba(53, 55, 48, 0.5);
  min-width: 80px;
  font-family: Courier, monospace;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
}

.button1:hover {
  scale: 1.1;
  color: #ffef08;
  animation: corner 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.button2 {
  height: 50px;
  width: 150px;
  font-size: 22px;
  margin: 20px;
  background-color: #ede58e41;
}
button2:hover > a {
  color: #ffef08;
}
.button2 a {
  text-decoration: none;
  color: #ffffff;
}
.button2 > a:hover {
  color: #ffef08;
}

.imgdiv {
  display: flex;
  flex-wrap: wrap;
}
/* Mobile/Hamburger menu ///////////////////////////////////////////////////////////////////*/

.menu-wrap {
  display: none;
  z-index: 5;
}

.menu-wrap .toggler {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
  width: 40px;
  height: 40px;
  opacity: 0;
  border-radius: 6px;
  padding: 6px;
}

.menu-wrap .hamburger {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 40px;
  height: 40px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.mbl-menu {
  font-size: 36px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*Lines Hamburger*/

.menu-wrap .hamburger > div {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #ffef08;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

.menu-wrap .hamburger > div:before,
.menu-wrap .hamburger > div:after {
  content: "";
  position: absolute;
  z-index: 8;
  top: -10px;
  width: 100%;
  height: 4px;
  background: inherit;
}

.menu-wrap .hamburger > div:after {
  top: 10px;
}

/*Toggler animate*/

.menu-wrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
}

.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}

/*Rotate on hover*/

.menu-wrap .toggler:checked:hover + .hamburger > div {
  transform: rotate(225deg);
}

/* show menu */
.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
  transform: scale(1);
  transition-duration: var(--menu-speed);
}

.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 2;
}

.menu-wrap .menu > div {
  background: #000000;
  border-radius: 50%;
  width: 200vw;
  height: 200vh;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
  opacity: 0.9;
  z-index: 500;
}

.menu-wrap .menu > div > span {
  width: 200%;
  padding-bottom: 200%;
  height: auto;
}

.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  transition: opacity 0.4s ease;
}
.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: #fff;
  font-size: 2rem;
  padding: 1rem;
}

.menu-wrap .menu > div > div > ul > li > a {
  color: #ffef08;
  text-decoration: none;
  transition: color 0.4s ease;
}

i {
  padding: 10px;
}

.dropdown_menu {
  position: absolute;
  width: 250px;
  top: 40px;
  right: 0px;
  border-radius: 5px;
  background-color: #222325e0;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  animation: slide 0.1s linear;
  transform-origin: top;
  text-align: right;
}
.dropdown_menu:before {
  content: "";
  position: absolute;
  align-self: right;
  border: 8px solid transparent;
  border-bottom: 8px solid rgb(117, 185, 130);
  right: 33px;
  top: -10px;
  margin-bottom: 3px;
  vertical-align: bottom;
}

.dropdown_menuItem {
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
  padding: 14px;
  margin: auto;
  color: rgb(247, 243, 243);
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  background-color: transparent;
  border: transparent;
  outline: none;
  display: block;
}

.footer-main {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px 20px 20px 20px;
  color: #ffef08;
  text-decoration: none;
}

.logoDB:hover {
  scale: 1.2;
  background-color: #ffef08;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0 0 60px 50px #ede58e, 0 0 90px 60px rgb(223, 229, 139),
    0 0 120px 100px rgb(246, 255, 0);
}
.nav_bar:hover .logoDB {
  animation: logoani 2s ease-out;
  scale: 1.3;
  background-color: #ffef08;
  transform: rotate(90deg);
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0 0 60px 50px #ede58e, 0 0 90px 60px rgb(223, 229, 139),
    0 0 120px 100px rgb(246, 255, 0);
}

.pp {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  filter: sepia(0.5);
}

.pp:hover {
  scale: 1.1;
  box-shadow: 0 0 5px 10px #ffef08;
  filter: saturate(2);
}
.pp:hover .showbox {
  filter: saturate(2);
}
@keyframes ppani {
}

@keyframes logoani {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: scale(0.8) rotate(90deg);
  }
  50% {
    transform: scale(0.9) rotate(90deg);
  }
  75% {
    transform: scale(1) rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
    box-shadow: 0 0 40px 60px #ede58e, 0 0 90px 70px rgb(223, 229, 139),
      0 0 120px 100px rgb(246, 255, 0);
  }
}

.navbtns {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

/* Landing starts here ///////////////////////////////////////////////////////////////////////////////////////*/
.landing {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  align-items: center;
}
.deskMain {
  display: flex;
  justify-content: space-between;
  padding: auto;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px;
  margin-top: 50px;
}
.introSide {
  height: 90vh;
  width: 40%;
  min-width: 200px;
  background-color: rgb(27, 26, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: auto;
  z-index: 2;
}

.introp {
  text-align: center;
}
.projSide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}
.boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.banner {
  height: 600px;
  width: 200px;
  background-color: rgb(27, 26, 26);
}
.box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: rgb(27, 26, 26, 0.5);
  margin: 20px;
  font-size: 15px;
}
@keyframes corner {
  0% {
    border-top-left-radius: 1% 50%;
  }
  12.5% {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  25% {
    border-top-right-radius: 1% 50%;
  }
  25.001% {
    border-top-right-radius: 50% 1%;
  }
  37.5% {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  50% {
    border-bottom-right-radius: 50% 1%;
  }
  50.001% {
    border-bottom-right-radius: 1% 50%;
  }
  62.5% {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  75% {
    border-bottom-left-radius: 1% 50%;
  }
  75.001% {
    border-bottom-left-radius: 50% 1%;
  }
  87.5% {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
  }
  100% {
    border-top-left-radius: 50% 1%;
  }
}

.showbox {
  height: 180px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  flex-direction: column;
  flex-wrap: wrap;
  color: #ffef08;
}

.hidbox {
  max-width: 300px;
  height: 180px;
  display: none;
  color: #ffffff;
  padding: 6px;
}

.boxes:hover .box {
  width: 100%;
}
.boxes:hover .hidbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.boxes:hover .showbox {
  filter: saturate(3);
}
.box:hover {
  animation: corner 3s;
  border-radius: 20%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-color: #ede58e49;
}

.demoTxt {
  display: flex;
}
.projSide:hover .demoTxt {
  display: none;
}
.myDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projimg {
  height: 140px;
  width: 180px;
  object-fit: contain;
  aspect-ratio: 3/2;
}

.demo {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 900;
}
/*cvpage  //////////////////////////////////////////////////////////////////////////////////////////*/

.cvPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nameme {
  font-weight: 700;
  font-size: xx-large;
}

.cvsec {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: -1;
}

.cv-row {
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  min-width: 70%;
  z-index: 10;
  margin: 20px;
  padding: 20px;
  z-index: 1;
}

.schools {
  background-color: #ede58e49;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 20px;
  padding: 10px;
  flex-wrap: wrap;
  z-index: -1;
}

/* Default hidden state for fade-in animation */
.hidden {
  opacity: 0;
  transform: translateX(10px); /* Slide in from below */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Visible state for fade-in animation */
.fade-in {
  opacity: 1;
  transform: translateX(0); /* Animate to original position */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* typewritwerrr */
.typewriter {
  margin-top: 60px;
}
.typewriter p {
  border-right: 0.15em solid orange;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  color: #060606;
}
.typewriter p:nth-child(1) {
  width: 19em;
  -webkit-animation: type 2s steps(30, end);
  animation: type 2s steps(30, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.typewriter p:nth-child(2) {
  width: 20em;
  opacity: 0;
  -webkit-animation: type2 2s steps(30, end);
  animation: type2 2s steps(30, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.typewriter p:nth-child(3) {
  width: 17em;
  opacity: 0;
  -webkit-animation: type3 2s steps(40, end),
    blink 0.5s step-end infinite alternate;
  animation: type3 2s steps(40, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

.box-head {
  color: #ffef08;
  z-index: 10;
  opacity: 100%;
  font-weight: bolder;
}

.square {
  height: 48px;
  width: 48px;
  background-color: #383232;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  color: #ffef08;
  font-weight: 900;
}
.square:hover {
  background-color: #ffef08;
  color: black;
}
.board-row {
  display: flex;
  flex-direction: row;
}

#ttt {
  font-size: 0.8em;
  color: #ffffff;
  margin-top: 0px;
}
.tic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
}
.tac {
  margin-bottom: 20px;
}
.toe {
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-board {
  position: relative;
}
.game-info {
  margin-top: 8px;
}

.header2 {
  font-size: 28px;
  color: #ffef08;
  font-weight: 900;
  font-family: "Lobster";
}
.header1 {
  font-size: 32px;
  color: #ffef08;
  font-weight: 900;
  font-family: "Lobster";
}
.header3 {
  font-size: 24px;
  color: #010101;
  font-weight: 900;
  font-family: "lobster";
}
.header3_bright {
  font-size: 24px;
  color: #ffef08;
  font-weight: 900;
  font-family: "lobster";
}

/*Contact starts here---------------------------------------------------------------*/
.contactMain {
  min-height: 70vh;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  z-index: 1;
  background-color: rgb(27, 26, 26, 0.5);
}
.contact {
  display: flex;
  flex-direction: column;
  margin: 10px 30px 10px 30px;
  align-items: center;
  justify-content: center;
  width: 40%;
  background-color: #00000049;
  height: 400px;
}
.social {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.float {
  height: 240px;
  width: 240px;
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.faIcon {
  color: #ffef08;
  margin: 40px;
}
.faIcon2 {
  font-size: 40px;
}
.faIcon:hover {
  box-shadow: 0 0 10px 10px #ffef08;
}
.formClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.formClass > p {
  margin-top: 10px;
}
.formClass > input,
textarea {
  margin: 10px 0px 10px 0px;
  height: 30px;
}

/*------------- GPT Starts here -------------------------------------------------------*/

.gptContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 80%;
  min-height: 30vh;
  background-color: #00000072;
  margin: 60px 0 60px 0;
}

.gptBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin: 20px;
  padding: 20px;
}
.generated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 76%;
  min-height: 100px;
  margin-bottom: 20px;
  background-color: rgba(53, 55, 48, 0.5);
  color: #000000;
  font-size: 20px;
  font-weight: 900;
  z-index: 1;
}

.formClassChat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.message {
  width: 70%;
  height: 30px;
  margin: 20px;
  padding: 20px;
  background-color: rgba(53, 55, 48, 0.5);
  color: #ffef08;
  border: none;
}

.user-message {
  color: #ffef08;
  font-weight: 200;
}
.bot-message {
  color: #08ff0c;
  font-weight: 200;
}

/* --------------------------------------randomPage--------------------------------------------- */
.randomPage {
  min-height: 70vh;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  background-color: rgb(27, 26, 26, 0.5);
  z-index: 1;
}
.todolist {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0 40px 0;
}

.todoInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #00000074;
  width: 60%;
  height: 100%;
  padding: 6px;
  height: 80px;
  margin: 5%;
}
.todos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000074;
  width: 60%;
  min-height: 200px;
  color: #ffef08;
  font-size: 20px;
  font-weight: 900;
  z-index: 1;
  padding: 20px;
}
.todo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #00000074;
  width: 60%;
  height: 100%;
  padding: 0;
  height: 80px;
}
#todoIn {
  width: 100%;
  height: 20px;
  padding: 20px;
  font-size: 20px;
  font-weight: 900;
  z-index: 1;
  border-radius: 8px;
}
.todoBtns {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.todoBtn {
  margin: 8px;
}
.todoBtn:hover {
  scale: 1.1;
  color: #ffef08;
}
.todoMsg {
  border-radius: 10px;
  font-size: 20px;
  font-weight: 900;
}

#addTodo {
  font-size: xxx-large;
  color: #ffef08;
  font-weight: 900;
}

#todoUl {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  min-height: 40px;
}

/*---------------------------------pomodoro---------------------------------------------*/
.pomo {
  width: 70%;
  min-height: 40vh;
  background-color: #9f9c762e;
  margin: 40px 0 40px 0;
  padding: 8px;
}

.timerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  height: 100%;
  padding: 20px;
  color: #ffef08;
  font-size: 2em;
  font-weight: 900;
  z-index: 1;
  margin: 0 13%;
}
.timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100px;
  color: #ffef08;
  font-weight: 900;
  z-index: 1;
  margin: 0;
}

.doro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.timerMsg {
  font-size: 1em;
  font-weight: 900;
  background-color: #ffef08;
  max-width: 60px;
  min-height: 60px;
  border-radius: 30%;
  text-align: center;
  font-size: 1em;
  margin: 0 6%;
}

.timerBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.timerBtn {
  margin: 0 6%;
}

@media screen and (max-width: 780px) {
  body {
    margin: 6px;
    display: flex;
  }
  .deskMain {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .introSide,
  .schools,
  .contact,
  .contactMain,
  .social,
  .float,
  .todos,
  .todo,
  .footer-main,
  .pomo,
  .randomPage {
    width: 86%;
  }
  .introSide {
    height: 80vh;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 380px;
    justify-content: space-around;
    width: 100%;
    padding: 8px;
  }

  .menu-wrap {
    display: inline;
  }
  .navbtns {
    display: none;
  }

  .projSide {
    width: 90%;
    margin-bottom: 100px;
  }
  .hidbox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .showbox {
    height: 100px;
    width: 180px;
    margin-top: 40px;
  }
  #projDesc {
    text-align: center;
  }

  .footer-main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contactMain {
    flex-direction: column;

    height: 750px;
  }
  .contact,
  .gpt,
  .todolist {
    width: 90%;
  }

  .float {
    width: 250px;
    height: 220px;
  }
  .todoInput {
    width: 90%;
  }
  .typewriter p {
    color: #ffef08;
  }
  .header3 {
    color: white;
  }
}
